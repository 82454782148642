import punycode from "punycode";

export const apiDomain = "api.xn----7sbbaakij2f6azd.xn--p1ai";
export const timezoneOffsetCurrent = 7 // в Красноярске (4), камчатское время (12), калининградское время (2)

export const domains = [
  {
    domain: "xn----7sbbaakij2f6azd.xn--p1ai", // ац-авангард.рф
    slug: "ac-avangard-rf",
    api: "api.xn----7sbbaakij2f6azd.xn--p1ai",
    siteId: 217,
    isIndex: true
  },
  // {
  //   domain: "xn--124-5cdal8dqgc4ak.xn--p1ai",
  //   slug: "avtosalon124-rf",
  //   api: "api.xn--124-5cdal8dqgc4ak.xn--p1ai",
  //   siteId: 216,
  //   isIndex: false
  // },
  {
    domain: "xn---124-43dan7esahc7al.xn--p1ai", // автосалон-124.рф
    slug: "avtosalon124-rf",
    api: "api.xn----7sbbaakij2f6azd.xn--p1ai",
    siteId: 219,
    isIndex: false
  },
  {
    domain: "krs-auto.ru",
    slug: "krs-auto-ru",
    api: "api.xn----7sbbaakij2f6azd.xn--p1ai",
    siteId: 458,
    isIndex: false
  },



]
export const devDomain = {
  domain: "xn----7sbbaakij2f6azd.xn--p1ai",
  slug: "ac-avangard-rf",
  api: "api.xn----7sbbaakij2f6azd.xn--p1ai",
  siteId: 217,
  isIndex: false
}

export const mainDomain = domains.find(value => value.isIndex) || domains[0]
export function findDomain(host) {
  if (host.split("https://")[1]) {
    let currentHost = host.split("https://")[1];
   return domains.find(value => punycode.toUnicode(value.domain) === punycode.toUnicode(currentHost)) || devDomain;
  } else {
    return domains.find(value => punycode.toUnicode(value.domain) === punycode.toUnicode(host)) || devDomain;
  }
}
