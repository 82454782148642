import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _798934a7 = () => interopDefault(import('../pages/About.vue' /* webpackChunkName: "pages/About" */))
const _5fe7add1 = () => interopDefault(import('../pages/Banks.vue' /* webpackChunkName: "pages/Banks" */))
const _f8600644 = () => interopDefault(import('../pages/Buyout.vue' /* webpackChunkName: "pages/Buyout" */))
const _d2e0e5ae = () => interopDefault(import('../pages/Contacts.vue' /* webpackChunkName: "pages/Contacts" */))
const _06cc9e3a = () => interopDefault(import('../pages/Cookie.vue' /* webpackChunkName: "pages/Cookie" */))
const _550dbb92 = () => interopDefault(import('../pages/Credit/index.vue' /* webpackChunkName: "pages/Credit/index" */))
const _c2df5a0e = () => interopDefault(import('../pages/Exchange.vue' /* webpackChunkName: "pages/Exchange" */))
const _9ef6951e = () => interopDefault(import('../pages/Favorites.vue' /* webpackChunkName: "pages/Favorites" */))
const _32f9e21a = () => interopDefault(import('../pages/Installments/index.vue' /* webpackChunkName: "pages/Installments/index" */))
const _d490f2bc = () => interopDefault(import('../pages/Privacy.vue' /* webpackChunkName: "pages/Privacy" */))
const _2acc2dd5 = () => interopDefault(import('../pages/reviews.vue' /* webpackChunkName: "pages/reviews" */))
const _265f501f = () => interopDefault(import('../pages/Thanks.vue' /* webpackChunkName: "pages/Thanks" */))
const _565e48c4 = () => interopDefault(import('../pages/Credit/_bank/index.vue' /* webpackChunkName: "pages/Credit/_bank/index" */))
const _21ceef8c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _7f0ce04c = () => interopDefault(import('../pages/_category/index.vue' /* webpackChunkName: "pages/_category/index" */))
const _211c9937 = () => interopDefault(import('../pages/_category/_mark/index.vue' /* webpackChunkName: "pages/_category/_mark/index" */))
const _12dc5d50 = () => interopDefault(import('../pages/_category/_mark/_model/index.vue' /* webpackChunkName: "pages/_category/_mark/_model/index" */))
const _36fa147c = () => interopDefault(import('../pages/_category/_mark/_model/_car/index.vue' /* webpackChunkName: "pages/_category/_mark/_model/_car/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/About",
    component: _798934a7,
    name: "About"
  }, {
    path: "/Banks",
    component: _5fe7add1,
    name: "Banks"
  }, {
    path: "/Buyout",
    component: _f8600644,
    name: "Buyout"
  }, {
    path: "/Contacts",
    component: _d2e0e5ae,
    name: "Contacts"
  }, {
    path: "/Cookie",
    component: _06cc9e3a,
    name: "Cookie"
  }, {
    path: "/Credit",
    component: _550dbb92,
    name: "Credit"
  }, {
    path: "/Exchange",
    component: _c2df5a0e,
    name: "Exchange"
  }, {
    path: "/Favorites",
    component: _9ef6951e,
    name: "Favorites"
  }, {
    path: "/Installments",
    component: _32f9e21a,
    name: "Installments"
  }, {
    path: "/Privacy",
    component: _d490f2bc,
    name: "Privacy"
  }, {
    path: "/reviews",
    component: _2acc2dd5,
    name: "reviews"
  }, {
    path: "/Thanks",
    component: _265f501f,
    name: "Thanks"
  }, {
    path: "/Credit/:bank",
    component: _565e48c4,
    name: "Credit-bank"
  }, {
    path: "/",
    component: _21ceef8c,
    name: "index"
  }, {
    path: "/:category",
    component: _7f0ce04c,
    name: "category"
  }, {
    path: "/:category/:mark",
    component: _211c9937,
    name: "category-mark"
  }, {
    path: "/:category/:mark/:model",
    component: _12dc5d50,
    name: "category-mark-model"
  }, {
    path: "/:category/:mark/:model/:car",
    component: _36fa147c,
    name: "category-mark-model-car"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
